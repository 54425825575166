import React from "react";
import { Link } from "gatsby";

import "../normalize.css";
import "./layout.css";
import Footer from "./footer";

import HomeIcon from "../icons/svg/blarney-castle.svg";

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    let header;

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            marginTop: 0,
            textAlign: "center"
          }}
        >
          {title}
        </h1>
      );
    } else {
      header = (
        <h3
          style={{
            marginTop: 0
          }}
          className="blog-header"
        >
          <Link
            className="flex-center"
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
              flexDirection: `column`
            }}
            to={`/`}
            title="take me home"
          >
            <HomeIcon
              className="icon--home"
              style={{ marginBottom: ".25em" }}
              alt="home icon"
            />
            {title}
          </Link>
        </h3>
      );
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: "40em",
          padding: "1.625rem 1.3125rem"
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <Footer />
      </div>
    );
  }
}

export default Layout;
