import React from "react";
import "./footer.css";

const Footer = () => (
  <footer>
    <span>Dan other places</span>
    {" - "}
    <a href="https://github.com/tavelli">github</a>
    {" - "}
    <a href="https://www.linkedin.com/in/tavelli/">linked in</a>
    {" - "}
    <a href="https://twitter.com/tavelli">twitter</a>
  </footer>
);

export default Footer;
